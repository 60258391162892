import "react-big-calendar/lib/css/react-big-calendar.css";
import "tailwindcss/tailwind.css";
import "../styles/codeblocks.css";
import "../styles/global.css";

import { NextPage } from "next";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";
import toast, { Toaster } from "react-hot-toast";
import { MutationCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useSessionStorage } from "react-use";

import ModalProvider from "@/components/modal/modal-provider";

import { UserNameContext } from "../components/user-name-context";

const CrispChat = dynamic(() => import("@/components/crisp-chat"), {
  ssr: false,
});

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: () => {
      toast.error(
        "Uh oh! Something went wrong. The issue has been logged and we'll fix it as soon as possible. Please try again later.",
      );
    },
  }),
});

const MyApp: NextPage<AppProps> = ({ Component, pageProps }) => {
  const sessionUserName = useSessionStorage<string>("userName", "");

  return (
    <QueryClientProvider client={queryClient}>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="google-tag" strategy="lazyOnload">
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
      page_path: window.location.pathname,
      });
  `}
      </Script>
      <Script id="clarity-tag" strategy="lazyOnload">
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "f35hvroete");
          `}
      </Script>

      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <CrispChat />
      <Toaster />
      <ModalProvider>
        <UserNameContext.Provider value={sessionUserName}>
          <Component {...pageProps} />
        </UserNameContext.Provider>
      </ModalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default appWithTranslation(MyApp);
